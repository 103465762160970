.center {
  margin: auto;
}

.portfolio {
  font-family: 'Work Sans', sans-serif;
  margin-left: 20px;
  margin-top: 20px;
}

.res-padding {
  padding-left: 10px;
  padding-right: 10px;
}

.img-small {
  height: 200px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.img-ed {
  height: 150px;
}

.tableauPlaceholder {
  width: 1260px; 
  height: 624px;
}

.tableauViz {
  width: 1260px;
  height: 624px;
  display:none;
}

.nav {
  background-color: #333333;
}


.cont {
  background-color: #99E1D9;
}

.li, #portfolio, #resume {
  color: #65B891;
  font-family: 'Work Sans', sans-serif;
  margin-right:0px;
}

.right {
  text-align: right;
}

.bio {
  color: #333333;
  /*text-shadow: .5px .5px #f7f7f7;*/
  font-family: 'Work Sans', sans-serif;
}

.name {
  color: #333333;
  font-family: 'Work Sans', sans-serif;
  padding-top: 20px;
  padding-bottom: 20px;
}

.ed {
  color: #333333;
  /*text-shadow: .5px .5px #f7f7f7;*/
  font-family: 'Work Sans', serif;

}
.thesis {
  font-size: 14px;
}

.italic {
  font-style: italic;
}
.ed-res {
  margin-left: auto;
}

.school {
  padding-top: 40px;
}

.sum-desc {
  width: 500px;
  padding-bottom: 20px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
